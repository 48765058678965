.FireComments-module_comments_-CUOE__Main {
  border: 1px solid var(--line-line);
  height: 30px;
  color: var(--text-primary);
  cursor: pointer;
  border-radius: 10px;
  align-items: center;
  gap: 5px;
  padding: 5px 10px 5px 5px;
  display: flex;
}

.FireComments-module_comments__icon--grey_bMaqk__Main {
  fill: var(--icons-gray);
  margin-left: 5px;
}

.FireComments-module_comments__icon--yellow_ZcDQJ__Main {
  fill: var(--icons-yellow);
}

.FireComments-module_comments__icon--red_pEHe5__Main {
  fill: var(--icons-red);
}

.DropdownMenu-module_dropdown_Dgieh__Main {
  background-color: var(--dropdown-bg);
  width: 100%;
  height: 40px;
  color: var(--dropdown-text);
  cursor: pointer;
  border: 1px solid var(--dropdown-stroke);
  box-sizing: border-box;
  border-radius: 10px;
  justify-content: center;
  padding: 7px 10px;
  display: flex;
  position: relative;
}

.DropdownMenu-module_dropdown_Dgieh__Main:hover {
  border-color: var(--dropdown-hover);
}

.DropdownMenu-module_dropdown--disabled_OIlVv__Main, .DropdownMenu-module_dropdown--disabled_OIlVv__Main:hover {
  border-color: var(--disabled-bg);
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: auto;
}

.DropdownMenu-module_dropdown--disabled_OIlVv__Main .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main, .DropdownMenu-module_dropdown--disabled_OIlVv__Main:hover .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--disabled-icons);
}

.DropdownMenu-module_dropdown--opened_QRv1T__Main {
  background-color: var(--dropdown-bg-active);
  border-color: var(--dropdown-bg-active);
  color: var(--dropdown-text-active);
}

.DropdownMenu-module_dropdown--opened_QRv1T__Main .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--icons-active);
}

.DropdownMenu-module_dropdown--teaser_MLpme__Main {
  background: none;
  border: none;
  padding: 0;
}

.DropdownMenu-module_dropdown--teaser_MLpme__Main:hover {
  border: none;
}

.DropdownMenu-module_dropdown--teaser_MLpme__Main.DropdownMenu-module_dropdown--opened_QRv1T__Main {
  color: var(--dropdown-text-active);
}

.DropdownMenu-module_dropdown--teaser_MLpme__Main.DropdownMenu-module_dropdown--opened_QRv1T__Main .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--icons-active);
}

.DropdownMenu-module_dropdown--header_icMkY__Main {
  height: 30px;
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main {
  width: auto;
  height: auto;
  color: var(--dropdown-text);
  background: none;
  border: none;
  padding: 0;
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main:hover {
  color: var(--dropdown-text-active);
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main:hover .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--icons-active);
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main.DropdownMenu-module_dropdown--opened_QRv1T__Main {
  color: var(--dropdown-text-active);
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main.DropdownMenu-module_dropdown--opened_QRv1T__Main .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--icons-active);
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main .DropdownMenu-module_dropdown__menu_ZXUQq__Main {
  width: auto;
  left: -15px;
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main .DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--icons-primary);
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main .DropdownMenu-module_dropdown-item_YNSHe__Main {
  white-space: nowrap;
  padding: 12px 15px;
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main .DropdownMenu-module_dropdown-item__tick_Cf-iW__Main {
  display: none;
}

.DropdownMenu-module_dropdown--standings_cgoMg__Main .DropdownMenu-module_dropdown-item__text_Zu24o__Main {
  color: var(--list-text);
}

.DropdownMenu-module_dropdown--error_U4zSZ__Main {
  border: 1px solid var(--input-error);
}

.DropdownMenu-module_dropdown__selected-item_-vSMp__Main {
  justify-content: space-between;
  align-items: center;
  gap: 2px;
  width: 100%;
  display: flex;
}

.DropdownMenu-module_dropdown__selected-item-text_Be4x9__Main {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.DropdownMenu-module_dropdown__selected-item--teaser_27m75__Main {
  justify-content: flex-start;
  gap: 10px;
}

.DropdownMenu-module_dropdown__selected-item--teaser-text_Fs1pl__Main {
  white-space: nowrap;
}

.DropdownMenu-module_dropdown__arrow-icon-wrapper_Uy17o__Main {
  align-items: center;
  display: flex;
}

.DropdownMenu-module_dropdown__arrow-icon_AmF6D__Main {
  fill: var(--icons-primary);
  width: 16px;
  height: 16px;
  transition: transform .25s, fill .25s;
}

.DropdownMenu-module_dropdown__arrow-icon--rotated_qwsdM__Main {
  fill: var(--icons-active);
  transform: rotate(180deg);
}

.DropdownMenu-module_dropdown__menu_ZXUQq__Main {
  border: 1px solid var(--line-line);
  background-color: var(--bg-block);
  z-index: 12;
  -ms-overflow-style: none;
  scrollbar-width: none;
  border-radius: 5px;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-height: 230px;
  padding: 10px;
  display: none;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  overflow: scroll;
}

.DropdownMenu-module_dropdown__menu_ZXUQq__Main::-webkit-scrollbar {
  display: none;
}

.DropdownMenu-module_dropdown__menu--active_lmMG9__Main {
  display: flex;
}

.DropdownMenu-module_dropdown__menu--teaser-active-menu_QAcpF__Main {
  border-radius: 0;
  width: 200px;
}

.DropdownMenu-module_dropdown-item_YNSHe__Main {
  border-radius: 5px;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 7px;
  display: flex;
}

.DropdownMenu-module_dropdown-item_YNSHe__Main:hover {
  background-color: var(--list-bg-hover);
}

.DropdownMenu-module_dropdown-item__tick_Cf-iW__Main {
  margin-right: 15px;
}

.DropdownMenu-module_dropdown-item__tick_Cf-iW__Main path {
  stroke: var(--icons-active);
}

.DropdownMenu-module_dropdown-item__text_Zu24o__Main {
  color: var(--list-text);
  width: 100%;
  display: block;
}

.DropdownMenu-module_dropdown-item--selected_wQlwA__Main, .DropdownMenu-module_dropdown-item--selected_wQlwA__Main:hover {
  background-color: var(--list-bg-active);
}

.DropdownMenu-module_dropdown-item--selected_wQlwA__Main .DropdownMenu-module_dropdown-item__text_Zu24o__Main, .DropdownMenu-module_dropdown-item--selected_wQlwA__Main:hover .DropdownMenu-module_dropdown-item__text_Zu24o__Main {
  color: var(--list-text-active);
}

.DropdownMenu-module_dropdown-item__icon_XJ7ud__Main {
  border-radius: 2px;
}

@media (max-width: 660px) {
  .DropdownMenu-module_dropdown_Dgieh__Main {
    padding: 10px;
  }

  .DropdownMenu-module_dropdown--header_icMkY__Main {
    padding: 5px;
  }

  .DropdownMenu-module_dropdown__selected-item--header_ou6d2__Main {
    justify-content: space-around;
    gap: 0;
  }

  .DropdownMenu-module_dropdown--teaser_MLpme__Main {
    padding: 0;
  }

  .DropdownMenu-module_dropdown--standings_cgoMg__Main {
    white-space: nowrap;
    padding: 0;
    position: static;
  }

  .DropdownMenu-module_dropdown--standings_cgoMg__Main .DropdownMenu-module_dropdown__menu_ZXUQq__Main {
    border: none;
    border-bottom: 1px solid var(--bg-page);
    border-radius: 0;
    width: 100%;
    max-height: 310px;
    top: 60px;
    left: 0;
  }

  .DropdownMenu-module_dropdown__menu_ZXUQq__Main {
    max-height: 160px;
  }

  .DropdownMenu-module_dropdown__menu--header-active_EJHDb__Main {
    padding: 5px;
  }

  .DropdownMenu-module_dropdown-item__text--header_1jL5p__Main {
    text-align: center;
  }
}

.NewUiText-module_text_qmUyw__Main {
  font-family: var(--montserrat-font), sans-serif;
  font-style: normal;
}

.NewUiText-module_text--h1_eoKkV__Main {
  letter-spacing: .15px;
  font-size: 18px;
  font-weight: 650;
  line-height: 25px;
}

.NewUiText-module_text--h2_-bwDU__Main {
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 680;
  line-height: 20px;
}

.NewUiText-module_text--h3_C80hV__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 680;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-1_EbJBq__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 480;
  line-height: 15px;
}

.NewUiText-module_text--subtitle-2_HH1yd__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 480;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-3_MKm6l__Main {
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 680;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-4_vp-5q__Main {
  letter-spacing: .15px;
  font-size: 13px;
  font-weight: 480;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-5_UJVQH__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 580;
  line-height: 20px;
}

.NewUiText-module_text--subtitle-6_VadI3__Main {
  letter-spacing: .15px;
  font-size: 28px;
  font-weight: 650;
  line-height: 35px;
}

.NewUiText-module_text--subtitle-7_2l4x8__Main {
  letter-spacing: .15px;
  font-size: 10px;
  font-weight: 580;
  line-height: 15px;
}

.NewUiText-module_text--subtitle-8_fr6r-__Main {
  letter-spacing: .15px;
  font-size: 20px;
  font-weight: 650;
  line-height: 30px;
}

.NewUiText-module_text--subtitle-9_ETeMn__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 580;
  line-height: 15px;
}

.NewUiText-module_text--body-1_FUoUN__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
}

.NewUiText-module_text--body-2_YOO3K__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 16px;
  font-weight: 680;
  line-height: 25px;
}

.NewUiText-module_text--body-3_if5Yy__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 380;
  line-height: 15px;
}

.NewUiText-module_text--body-4_72aiB__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.NewUiText-module_text--button_FLtVN__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-weight: 580;
  line-height: 20px;
}

.NewUiText-module_text--button-2_-PFsU__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-weight: 580;
  line-height: 15px;
}

.NewUiText-module_text--caption_V2DW-__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .4px;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.NewUiText-module_text--overline_fAPqz__Main {
  letter-spacing: .15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 680;
  line-height: 15px;
}

.NewUiText-module_text--overline-2_0SY9x__Main {
  letter-spacing: .15px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 480;
  line-height: 15px;
}

.NewUiText-module_text--link_zCxhk__Main:hover {
  color: var(--text-link-active);
}

.NewUiText-module_text--important-info_012Lw__Main {
  letter-spacing: .25px;
  font-size: 46px;
  font-weight: 800;
  line-height: 56px;
}

.LikesBlock-module_likes_xD7ns__Main {
  border: 1px solid var(--line-line);
  height: 30px;
  color: var(--text-primary);
  background-color: var(--bg-block);
  border-radius: 10px;
  align-items: center;
  gap: 5px;
  padding: 5px 10px 5px 5px;
  display: flex;
  position: relative;
}

.LikesBlock-module_likes__icon_IxgR6__Main {
  fill: var(--icons-primary);
}

.LikesBlock-module_likes__prompt_4rHGY__Main {
  opacity: 0;
  z-index: 10;
  color: var(--tooltip-text);
  background-color: var(--tooltip-bg);
  text-align: center;
  white-space: nowrap;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 5px 15px;
  transition: opacity .15s;
  position: absolute;
  top: -35px;
  right: 0;
}

.LikesBlock-module_likes_xD7ns__Main:hover .LikesBlock-module_likes__prompt_4rHGY__Main {
  opacity: 1;
}

.Tabs-module_tabs-wrapper_pKz44__Main {
  border-top: 1px solid var(--line-line);
  border-bottom: 1px solid var(--line-line);
  background: var(--bg-block);
  padding: 0 10px;
}

.Tabs-module_tabs-wrapper--calendar_026Eg__Main {
  align-items: center;
  display: flex;
}

.Tabs-module_tabs-wrapper_pKz44__Main .Tabs-module_tabs__navigation_Xvn-I__Main {
  background: var(--bg-block);
}

.Tabs-module_tabs-wrapper--transparent_91KO4__Main {
  background: none;
}

.Tabs-module_tabs-wrapper--transparent_91KO4__Main .Tabs-module_tabs__navigation_Xvn-I__Main {
  background: var(--bg-page);
}

.Tabs-module_tabs-wrapper--without-top-border_kq0ed__Main {
  border-top: none;
}

.Tabs-module_tabs-wrapper--without-bottom-border_HLpXo__Main {
  border-bottom: none;
}

.Tabs-module_tabs_z-Cun__Main {
  white-space: nowrap;
  z-index: 2;
  width: 100%;
  height: 40px;
  position: relative;
}

.Tabs-module_tabs__navigation_Xvn-I__Main {
  top: 10px !important;
}

.Tabs-module_tabs--with-dropdown_i6Vjf__Main {
  align-items: center;
  display: flex;
}

.Tabs-module_tabs__tab_LJOUA__Main {
  cursor: pointer;
  align-items: center;
  display: flex;
  position: relative;
}

.Tabs-module_tabs__tab-section_xze6K__Main {
  width: auto;
  max-height: 420px;
  display: none;
  overflow-y: scroll;
  border-top: none !important;
}

.Tabs-module_tabs__tab-section--open_-jkyJ__Main {
  display: block;
}

.Tabs-module_tabs__tab-section_xze6K__Main .Tabs-module_tabs__tab-section__column_YVR3B__Main {
  max-width: 250px;
}

.Tabs-module_tabs__tab-section_xze6K__Main .Tabs-module_tabs__tab-section__item_U4NTd__Main {
  width: 250px;
}

.Tabs-module_tabs__tab-section_xze6K__Main a {
  height: 40px;
}

.Tabs-module_tabs__tab-arrow_8W1XA__Main {
  cursor: pointer;
}

.Tabs-module_tabs__tab-arrow_8W1XA__Main path {
  fill: var(--text-secondary);
}

.Tabs-module_tabs__tab-arrow--closed_OIamp__Main {
  transform: rotate(.5turn);
}

.Tabs-module_tabs__tab--active_ojlVS__Main .Tabs-module_tabs__tab-arrow_8W1XA__Main path {
  fill: var(--line-active);
}

.Tabs-module_tabs__tab--active_ojlVS__Main:after {
  content: "";
  background-color: var(--line-active);
  z-index: 11;
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Tabs-module_tabs__tab--match-chat-counter_NP3Mo__Main {
  background-color: var(--label-notifications-message-bg);
  max-width: 40px;
  height: 20px;
  color: var(--label-notifications-message-text);
  border-radius: 20px;
  align-items: center;
  margin-left: 5px;
  padding: 0 5px;
  display: flex;
}

.Tabs-module_tabs__tab-title_urE9x__Main {
  cursor: pointer;
  height: 40px;
  color: var(--text-secondary);
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  display: flex;
}

.Tabs-module_tabs__tab-title--active_WKlZO__Main {
  cursor: auto;
  text-align: center;
  color: var(--text-link-active);
  align-items: center;
  font-weight: 700;
  display: flex;
  position: relative;
}

.Tabs-module_tabs__tab-title--external_SPsqy__Main:hover {
  color: var(--text-link-active);
}

.Tabs-module_tabs__date-tabs_WqOye__Main {
  width: calc(100% - 40px);
}

.Tabs-module_tabs__more-button_P9ToE__Main {
  padding: 0 15px;
}

.Tabs-module_tabs__more-button_P9ToE__Main:hover {
  color: var(--text-link-active);
  fill: var(--icons-active);
}

.Tabs-module_tabs__dropdown-wrapper_rdTEe__Main {
  background-color: var(--bg-block);
  z-index: 10;
  width: auto;
  padding: 20px;
  position: absolute;
  top: 41px;
  right: -10px;
}

.Tabs-module_tabs__dropdown_j4qdQ__Main {
  grid-template-columns: repeat(3, 170px);
  gap: 0 20px;
  display: grid;
}

.Tabs-module_tabs__dropdown__item_NKpKl__Main {
  border-radius: 5px;
  justify-content: flex-start;
}

.Tabs-module_tabs__dropdown__item_NKpKl__Main:hover {
  background: var(--list-bg-active);
  color: var(--list-text-active);
}

@media (max-width: 660px) {
  .Tabs-module_tabs__navigation_Xvn-I__Main {
    border: none;
    width: 20px;
    height: 20px;
    display: block;
  }

  .Tabs-module_tabs__dropdown-wrapper_rdTEe__Main {
    border: 1px solid var(--line-line);
    border-radius: 5px;
    width: 100%;
    height: auto;
    max-height: 150px;
    padding: 10px 5px;
    position: absolute;
    top: 50px;
    right: 0;
  }

  .Tabs-module_tabs__dropdown_j4qdQ__Main {
    max-height: 130px;
    display: block;
    overflow-y: scroll;
  }

  .Tabs-module_tabs__dropdown_j4qdQ__Main::-webkit-scrollbar {
    width: 5px;
  }

  .Tabs-module_tabs__dropdown_j4qdQ__Main::-webkit-scrollbar-thumb {
    background: var(--dropdown-stroke);
    border-radius: 5px;
  }

  .Tabs-module_tabs__dropdown__item_NKpKl__Main:not(:last-child) {
    margin-bottom: 5px;
  }
}

.PostCard-module_blog-card_Dz5H4__Main {
  background-color: var(--bg-block);
  cursor: pointer;
  border-radius: 15px;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 160px;
  padding: 10px;
  display: flex;
  overflow: hidden;
}

.PostCard-module_blog-card__wrapper_9yrHp__Main {
  width: 100%;
  position: relative;
}

.PostCard-module_blog-card--without-img_rnXqD__Main {
  height: auto;
}

.PostCard-module_blog-card--widget_qRh1E__Main {
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  height: 90px;
  padding: 0;
}

.PostCard-module_blog-card--widget_qRh1E__Main .PostCard-module_blog-card__main-info_J1OLK__Main {
  gap: 0;
}

.PostCard-module_blog-card--widget_qRh1E__Main .PostCard-module_blog-card__image_Wh1fO__Main {
  min-width: 90px;
  max-width: 90px;
}

.PostCard-module_blog-card--vertical_1J21T__Main {
  flex-direction: column;
  justify-content: flex-start;
  height: 340px;
}

.PostCard-module_blog-card--vertical_1J21T__Main .PostCard-module_blog-card__image_Wh1fO__Main {
  min-width: 100%;
  max-width: 100%;
  height: 165px;
}

.PostCard-module_blog-card--vertical_1J21T__Main .PostCard-module_blog-card__content_o3jJ-__Main {
  gap: 10px;
  height: calc(100% - 185px);
}

.PostCard-module_blog-card__content_o3jJ-__Main {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  display: flex;
  position: relative;
}

.PostCard-module_blog-card__content--with-blog_4Rpqu__Main {
  padding-top: 40px;
}

.PostCard-module_blog-card__main-info_J1OLK__Main {
  flex-direction: column;
  gap: 10px;
  display: flex;
}

.PostCard-module_blog-card__title_H7WgT__Main {
  -webkit-line-clamp: 3;
  color: var(--text-primary);
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.PostCard-module_blog-card__title--short_4u2lr__Main {
  -webkit-line-clamp: 2;
}

.PostCard-module_blog-card__bottom-panel_pwVFu__Main {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.PostCard-module_blog-card__bottom-panel--actions_aMKm7__Main {
  align-items: center;
  gap: 10px;
  display: flex;
}

.PostCard-module_blog-card__edit-and-delete_-jA0c__Main {
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-left: 10px;
  display: flex;
}

.PostCard-module_blog-card__edit-button_8ky4u__Main, .PostCard-module_blog-card__delete-button_13iFC__Main {
  background-color: var(--bg-component);
  cursor: pointer;
  border: none;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.PostCard-module_blog-card__edit-button_8ky4u__Main:hover svg, .PostCard-module_blog-card__delete-button_13iFC__Main:hover svg {
  fill: var(--icons-active);
}

.PostCard-module_blog-card__edit-button_8ky4u__Main svg {
  fill: var(--icons-primary);
}

.PostCard-module_blog-card__delete-button_13iFC__Main svg {
  fill: var(--icons-red);
}

.PostCard-module_blog-card__image_Wh1fO__Main {
  object-position: top;
  border-radius: 10px;
  min-width: 250px;
  max-width: 250px;
  height: 100%;
  overflow: hidden;
}

.PostCard-module_blog-card__image_Wh1fO__Main img {
  transition: transform .2s;
}

.PostCard-module_blog-card__image_Wh1fO__Main:hover img {
  transform: scale(1.1);
}

.PostCard-module_blog-card__description_onGMr__Main {
  color: var(--text-secondary);
  -webkit-line-clamp: 2;
  word-break: break-word;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.PostCard-module_blog-card__description--short_pQhxV__Main {
  -webkit-line-clamp: 1;
}

.PostCard-module_blog-card__blog_3hVPb__Main {
  z-index: 1;
  align-items: center;
  gap: 5px;
  display: flex;
  position: absolute;
  top: 10px;
  left: 280px;
}

.PostCard-module_blog-card__blog--without-post-image_S-pSn__Main {
  left: 10px;
}

.PostCard-module_blog-card__blog-avatar_Xhsq5__Main {
  border-radius: 5px;
  margin-right: 10px;
}

.PostCard-module_blog-card__blog-name_aylsq__Main, .PostCard-module_blog-card__blog-author_d3kAo__Main {
  color: var(--text-primary);
  margin-right: 5px;
  display: inline;
}

.PostCard-module_blog-card__blog-in_A-ZHc__Main {
  color: var(--text-tertiary);
  margin-right: 5px;
  display: inline;
}

.PostCard-module_blog-card__time_m-Zmj__Main {
  color: var(--text-secondary);
}

.PostCard-module_blog-card__label_yN3qx__Main {
  margin-left: 13px;
  position: relative;
}

.PostCard-module_blog-card__label--spec_CWDtB__Main {
  color: var(--text-link-active);
}

.PostCard-module_blog-card__label--status_cMwmN__Main {
  color: var(--text-primary);
}

.PostCard-module_blog-card__label_yN3qx__Main:before {
  content: "";
  background-color: var(--icons-gray);
  border-radius: 50%;
  width: 3px;
  height: 3px;
  position: absolute;
  top: 50%;
  left: -7.5px;
  transform: translateY(-50%);
}

.PostCard-module_blog-card__not-published_zCVw5__Main {
  color: var(--text-primary);
}

.PostCard-module_blog-card--moderator_smyw5__Main {
  overflow: visible;
}

@media (max-width: 660px) {
  .PostCard-module_blog-card--not-widget_0g7zi__Main {
    flex-direction: column;
    height: auto;
  }

  .PostCard-module_blog-card--not-widget_0g7zi__Main .PostCard-module_blog-card__title_H7WgT__Main {
    -webkit-box-orient: inherit;
  }

  .PostCard-module_blog-card--not-widget_0g7zi__Main .PostCard-module_blog-card__description_onGMr__Main {
    -webkit-line-clamp: 3;
  }

  .PostCard-module_blog-card--not-widget_0g7zi__Main .PostCard-module_blog-card__image_Wh1fO__Main {
    min-width: 100%;
    max-width: calc(100vw - 40px);
    height: 50vw;
  }

  .PostCard-module_blog-card--not-widget_0g7zi__Main .PostCard-module_blog-card__content_o3jJ-__Main {
    gap: 20px;
  }

  .PostCard-module_blog-card__blog_3hVPb__Main {
    top: calc(50vw + 30px);
    left: 10px;
  }

  .PostCard-module_blog-card__blog--without-post-image_S-pSn__Main {
    top: 10px;
  }

  .PostCard-module_blog-card--vertical_1J21T__Main .PostCard-module_blog-card__image_Wh1fO__Main {
    height: 50vw;
  }

  .PostCard-module_blog-card--vertical_1J21T__Main .PostCard-module_blog-card__content_o3jJ-__Main {
    height: 100%;
  }

  .PostCard-module_blog-card--vertical_1J21T__Main .PostCard-module_blog-card_title_1Voyz__Main {
    -webkit-line-clamp: 3;
  }
}

.Image-module_image__wrapper_8o5iN__Main {
  width: 100%;
  height: 100%;
  position: relative;
}

.Image-module_image__wrapper__open_xFWY-__Main {
  width: 50vw;
  position: relative;
}

.Image-module_image__wrapper_8o5iN__Main img {
  object-position: top;
}

@media (max-width: 660px) {
  .Image-module_image__wrapper__open_xFWY-__Main {
    width: 90vw;
  }
}

.ModalHOC-module_modal__wrapper_11U4I__Main {
  z-index: 5200;
  background-color: var(--fullscreen-bg);
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.ModalHOC-module_modal__content_PcB30__Main {
  background-color: var(--bg-block);
  color: var(--text-primary);
  border-radius: 15px;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

@media (max-width: 660px) {
  .ModalHOC-module_modal__content_PcB30__Main {
    padding: 20px 10px;
  }
}

.CustomRatingItem-module_custom-rating_SYCHM__Main {
  background-color: var(--bg-block);
  border-radius: 15px;
  width: 420px;
  height: 100%;
  padding: 10px 10px 5px;
}

.CustomRatingItem-module_custom-rating--vertical_-FVNV__Main {
  width: 300px;
}

.CustomRatingItem-module_custom-rating--in-central-column_YnRPs__Main {
  width: 600px;
}

.CustomRatingItem-module_custom-rating__header_5xF8H__Main {
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  height: auto;
  margin-bottom: 5px;
  padding: 10px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.CustomRatingItem-module_custom-rating__header--yellow_w1gIs__Main {
  background-image: url("https://pictures.ua.tribuna.com/image/3963309e-9e1b-4706-8bbf-3dd94b88b257?width=1260&quality=70");
}

.CustomRatingItem-module_custom-rating__header--pink_SLIyg__Main {
  background-image: url("https://pictures.ua.tribuna.com/image/41625b72-bf5b-4dcc-83dd-25d9246600c0?width=1260&quality=70");
}

.CustomRatingItem-module_custom-rating__header-bg_Tm4-w__Main {
  z-index: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CustomRatingItem-module_custom-rating__header-name_xXDPD__Main {
  color: var(--text-black);
  z-index: 1;
}

.CustomRatingItem-module_custom-rating__all-link_91g0Z__Main {
  border: 1px solid var(--text-black);
  z-index: 1;
  height: 30px;
  color: var(--text-black);
  border-radius: 40px;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.CustomRatingItem-module_custom-rating__list_c6k3K__Main {
  margin-bottom: 0;
  padding: 0;
}

.CustomRatingItem-module_custom-rating__bookie_d0zB6__Main {
  height: 80px;
  padding: 15px 0;
}

.CustomRatingItem-module_custom-rating__bookie-top_hf-Br__Main {
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-bottom: 10px;
  display: flex;
}

.CustomRatingItem-module_custom-rating__bookie-left_Hdt--__Main {
  align-items: center;
  gap: 10px;
  display: flex;
}

.CustomRatingItem-module_custom-rating__bookie-logo-wrapper_EWcLI__Main {
  cursor: pointer;
  min-width: 60px;
  max-width: 60px;
  height: 20px;
  max-height: 20px;
}

.CustomRatingItem-module_custom-rating__bookie-logo-wrapper_EWcLI__Main img {
  object-position: center;
  max-height: 20px !important;
}

.CustomRatingItem-module_custom-rating__bookie-review-icon_8IUJh__Main {
  fill: var(--icons-gray-dark);
  cursor: pointer;
  padding: 2px;
}

.CustomRatingItem-module_custom-rating__bookie-bonus-icon_oZOgl__Main {
  fill: var(--icons-primary);
  cursor: pointer;
}

.CustomRatingItem-module_custom-rating__bookie-bonus-partner-icon_c07cr__Main {
  fill: var(--icons-yellow);
}

.CustomRatingItem-module_custom-rating__bookie-bonus-text_sxcoG__Main {
  color: var(--text-primary);
  cursor: pointer;
}

.CustomRatingItem-module_custom-rating__bookie_d0zB6__Main:not(:last-child) {
  border-bottom: 1px dashed var(--line-line);
}

.CustomRatingItem-module_custom-rating_SYCHM__Main li {
  list-style: none;
}

@media (max-width: 660px) {
  .CustomRatingItem-module_custom-rating_SYCHM__Main, .CustomRatingItem-module_custom-rating--vertical_-FVNV__Main, .CustomRatingItem-module_custom-rating--in-central-column_YnRPs__Main {
    width: 100%;
  }
}

.CustomRatingsWidget-module_custom-ratings-widget_9U2Ov__Main {
  position: relative;
}

.CustomRatingsWidget-module_custom-ratings-widget__header_xuxhk__Main {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

.CustomRatingsWidget-module_custom-ratings-widget__title_D5yx0__Main {
  color: var(--text-primary);
}

.CustomRatingsWidget-module_custom-ratings-widget__navigation_t7-R-__Main {
  align-items: center;
  gap: 25px;
  display: flex;
}

.CustomRatingsWidget-module_custom-ratings-widget__navigation--vertical_qQHf6__Main {
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -40px;
  left: 0;
}

.CustomRatingsWidget-module_custom-ratings-widget__counter_Fn1gd__Main {
  color: var(--text-secondary);
}

.CustomRatingsWidget-module_custom-ratings-widget__arrow_Ws7Bw__Main {
  background-color: var(--bg-block);
  border: 1px solid var(--line-line);
  cursor: pointer;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  padding: 4px;
}

.CustomRatingsWidget-module_custom-ratings-widget__arrow_Ws7Bw__Main svg {
  fill: var(--icons-primary);
}

.CustomRatingsWidget-module_custom-ratings-widget__item-wrapper_SlDIe__Main {
  height: 100%;
}

@media (max-width: 660px) {
  .CustomRatingsWidget-module_custom-ratings-widget__navigation_t7-R-__Main {
    justify-content: space-between;
    width: calc(100vw - 20px);
    position: absolute;
    bottom: -40px;
    left: 0;
  }

  .CustomRatingsWidget-module_custom-ratings-widget__item-wrapper_SlDIe__Main {
    width: calc(100vw - 20px);
  }
}

.SectionDropdown-module_section-dropdown_H6CVy__Main {
  white-space: nowrap;
  z-index: 10;
  background-color: var(--bg-block);
  border: 1px solid var(--line-line);
  width: auto;
  position: absolute;
  top: 100%;
}

.SectionDropdown-module_section-dropdown__subsections_crnLz__Main {
  border-right: 1px solid var(--line-line);
  background-color: var(--bg-component);
  flex-direction: column;
  gap: 20px;
  padding: 35px 20px;
  display: flex;
}

.SectionDropdown-module_section-dropdown__content_prugb__Main {
  flex-wrap: nowrap;
  gap: 20px;
  padding: 10px;
  display: flex;
}

@media (max-width: 1024px) {
  .SectionDropdown-module_section-dropdown--main-menu_yufR2__Main {
    background-color: var(--bg-component);
    border: none;
    flex-direction: column;
    position: static;
  }

  .SectionDropdown-module_section-dropdown--main-menu_yufR2__Main .SectionDropdown-module_section-dropdown__subsections_crnLz__Main {
    border: none;
    gap: 0;
    padding: 0;
  }

  .SectionDropdown-module_section-dropdown--main-menu_yufR2__Main .SectionDropdown-module_section-dropdown__content_prugb__Main {
    flex-direction: column;
    gap: 0;
    width: auto;
    padding: 0;
  }
}

.SectionDropdownColumn-module_section-dropdown-column_UCZ70__Main {
  flex-direction: column;
  gap: 10px;
  max-width: 200px;
  display: flex;
}

.SectionDropdownColumn-module_section-dropdown-column__title_RpvBs__Main {
  color: var(--text-link-active);
  cursor: pointer;
  margin: 0 10px 10px;
}

.SectionDropdownColumn-module_section-dropdown-column__title_RpvBs__Main:hover .SectionDropdownColumn-module_section-dropdown-column__title-text_3y3pP__Main {
  color: var(--text-link-active);
}

.SectionDropdownColumn-module_section-dropdown-column__list_NzWhi__Main {
  flex-direction: column;
  display: flex;
}

.SectionDropdownColumn-module_section-dropdown-column__item_30TOW__Main {
  white-space: pre-wrap;
  border-radius: 5px;
  align-items: center;
  width: 200px;
  min-height: 40px;
  padding: 10px 15px;
  display: flex;
}

.SectionDropdownColumn-module_section-dropdown-column__item_30TOW__Main:hover {
  background-color: var(--bg-component);
}

.SectionDropdownColumn-module_section-dropdown-column__item_30TOW__Main:hover .SectionDropdownColumn-module_section-dropdown-column__item-title_NIDmQ__Main {
  color: var(--text-link-active);
}

.SectionDropdownColumn-module_section-dropdown-column__image_ynszc__Main {
  background-color: var(--bg-component);
  border-radius: 50%;
  width: 20px;
  min-width: 20px;
  height: 20px;
  margin-right: 10px;
  overflow: hidden;
}

.SectionDropdownColumn-module_section-dropdown-column__item-title_NIDmQ__Main {
  color: var(--list-text);
  cursor: pointer;
}

@media (max-width: 1024px) {
  .SectionDropdownColumn-module_section-dropdown-column--main-menu_wdCV-__Main {
    max-width: none;
  }

  .SectionDropdownColumn-module_section-dropdown-column--main-menu_wdCV-__Main .SectionDropdownColumn-module_section-dropdown-column__item_30TOW__Main {
    width: 100%;
    color: var(--list-text);
    border-bottom: 1px solid var(--line-line);
    padding: 15px 32px;
    display: flex;
  }

  .SectionDropdownColumn-module_section-dropdown-column--main-menu_wdCV-__Main .SectionDropdownColumn-module_section-dropdown-column__title-text_3y3pP__Main {
    color: var(--list-text);
    border-bottom: 1px solid var(--line-line);
    padding: 15px 32px;
    display: block;
  }
}

.ReadMore-module_read-more_n019K__Main {
  z-index: 1;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  display: flex;
  position: relative;
}

.ReadMore-module_read-more__title_-tT4j__Main {
  color: var(--text-primary);
}

.ReadMore-module_read-more__arrow-icon_Gpgc-__Main {
  fill: var(--icons-primary);
  width: 16px;
  height: 16px;
  transition: transform .25s, fill .25s;
}

.ReadMore-module_read-more__arrow-icon--rotated_7nSrF__Main {
  transform: rotate(180deg);
}

.ReadMore-module_read-more_n019K__Main:hover .ReadMore-module_read-more__title_-tT4j__Main {
  color: var(--text-link-active);
}

.ReadMore-module_read-more_n019K__Main:hover .ReadMore-module_read-more__arrow-icon_Gpgc-__Main {
  fill: var(--icons-active);
}

.Avatar-module_avatar-wrapper_4ZDfV__Main {
  width: var(--width);
  min-width: var(--width);
  height: var(--height);
  min-height: var(--height);
  justify-content: center;
  align-items: center;
  line-height: 0;
  display: flex;
  overflow: hidden;
}

.Avatar-module_avatar-wrapper__wrapper_Yxca4__Main {
  background-color: var(--icons-placeholder-bg);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.Avatar-module_avatar-wrapper__icon_nqLgJ__Main {
  fill: var(--icons-placeholder-logo);
}

.InlinePostCard-module_inline-post-item_oSR9s__Main {
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  display: flex;
}

.InlinePostCard-module_inline-post-item__published-time_-Gn35__Main {
  color: var(--text-tertiary);
  vertical-align: baseline;
  display: inline-block;
}

.InlinePostCard-module_inline-post-item__title-text_1CnkD__Main {
  color: var(--text-primary);
  vertical-align: baseline;
}

.InlinePostCard-module_inline-post-item__text-wrapper_9usnp__Main {
  color: var(--text-primary);
}

.InlinePostCard-module_inline-post-item__info_mcGs8__Main {
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

@media (max-width: 660px) {
  .InlinePostCard-module_inline-post-item--show-ten_skaiO__Main:nth-child(n+11) {
    display: none;
  }
}

.UiText-module_text_aWC6D__Main {
  font-family: var(--montserrat-font), sans-serif;
  font-style: normal;
}

.UiText-module_text--h1_6bhwR__Main {
  letter-spacing: .25px;
  font-size: 32px;
  font-weight: 700;
  line-height: 39px;
}

.UiText-module_text--h2_YuPwS__Main {
  letter-spacing: .25px;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
}

.UiText-module_text--h3_P5fV4__Main {
  letter-spacing: .25px;
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
}

.UiText-module_text--h4_Bwu1n__Main {
  letter-spacing: .25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
}

.UiText-module_text--h5_Jp8HF__Main {
  letter-spacing: .25px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.UiText-module_text--h6_NRjeq__Main {
  letter-spacing: .25px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.UiText-module_text--h7_2qCoq__Main {
  letter-spacing: .15px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

.UiText-module_text--subtitle-1_OVEp9__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}

.UiText-module_text--subtitle-2_AyUMj__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.UiText-module_text--subtitle-3_MyQFf__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .1px;
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
}

.UiText-module_text--subtitle-4_Qp4Ld__Main {
  letter-spacing: .25px;
  font-size: 8px;
  font-weight: 500;
  line-height: 9.75px;
}

.UiText-module_text--subtitle-5_-f8Ht__Main {
  letter-spacing: .25px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.6px;
}

.UiText-module_text--body-1_c2QB2__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

.UiText-module_text--body-2_ieVL3__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
}

.UiText-module_text--body-3_NTvlg__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
}

.UiText-module_text--body-4_A10xg__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}

.UiText-module_text--body-5_BTyN6__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
}

.UiText-module_text--body-6_jjLlq__Main {
  font-family: var(--roboto-font), sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.UiText-module_text--button_4oqd9__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
}

.UiText-module_text--caption_0pAU7__Main {
  font-family: var(--roboto-font), sans-serif;
  letter-spacing: .4px;
  font-size: 12px;
  font-style: italic;
  line-height: 14px;
}

.UiText-module_text--overline_7PE45__Main {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
}

.UiText-module_text--link_lZmoh__Main:hover {
  color: var(--text-link-active);
}

.UiText-module_text--important-info_c8pZX__Main {
  letter-spacing: .25px;
  font-size: 46px;
  font-weight: 800;
  line-height: 56px;
}

.Modal-module_modal__header_aOEJ4__Main {
  text-align: end;
}

.Modal-module_modal__header_aOEJ4__Main svg {
  cursor: pointer;
}

.Modal-module_modal__block_lgZnu__Main {
  flex-flow: column;
  justify-content: center;
  align-items: center;
  max-width: 320px;
  display: flex;
}

.Modal-module_modal__block__title_-e2M6__Main {
  text-align: center;
  margin-bottom: 20px;
}

.Modal-module_modal__block__button_-SyfF__Main {
  margin-bottom: 10px;
}

.Modal-module_modal__block__warning-message_a1ysh__Main {
  align-items: center;
  max-width: 320px;
  padding: 10px 10px 0;
  display: flex;
}

.Modal-module_modal__block__warning-message--icon_mVtcP__Main {
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
  margin-right: 10px;
  display: flex;
}

.Modal-module_modal__block__warning-message--icon_mVtcP__Main svg {
  fill: var(--icons-gray);
}

.Modal-module_modal__block__warning-message--title_tQTx9__Main {
  color: var(--text-primary);
}

.TagColor-module_color-tag_G5oMP__Main {
  text-align: center;
  cursor: default;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 20px;
  padding: 0 5px;
  display: inline-flex;
}

.TagColor-module_color-tag__wrapper_WDXZL__Main {
  position: relative;
}

.TagColor-module_color-tag__prompt_K9q-N__Main {
  z-index: 10;
  color: var(--text-primary-white);
  background-color: var(--icons-primary);
  text-align: center;
  border-radius: 5px;
  width: auto;
  height: auto;
  padding: 5px 15px;
  display: none;
  position: absolute;
  top: 30px;
}

.TagColor-module_color-tag_G5oMP__Main:hover ~ .TagColor-module_color-tag__prompt_K9q-N__Main {
  display: block;
}

.TagColor-module_color-tag--green_Ai4fx__Main {
  color: var(--label-bonus-green);
  background-color: var(--label-bonus-green-bg);
}

.TagColor-module_color-tag--yellow_MpQcR__Main {
  color: var(--label-bonus-yellow);
  background-color: var(--label-bonus-yellow-bg);
}

.TagColor-module_color-tag--blue_2wX-Q__Main {
  color: var(--label-bonus-blue);
  background-color: var(--label-bonus-blue-bg);
}

.TagColor-module_color-tag--grey_qiXyd__Main {
  color: var(--label-general-1-text);
  background-color: var(--label-general-1-bg);
}

.TagColor-module_color-tag__copy-icon_sA2Wc__Main {
  width: 10px;
  height: 11px;
  fill: var(--label-general-3-text);
  cursor: pointer;
  margin-left: 5px;
}

.Ui-button-module_btn_uGeHD__Main {
  text-align: center;
  text-overflow: ellipsis;
  cursor: pointer;
  font-family: var(--montserrat-font), sans-serif;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border-radius: 10px;
  outline: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.Ui-button-module_btn--text-type-button_8YCYr__Main {
  letter-spacing: .15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 580;
  line-height: 20px;
}

.Ui-button-module_btn--text-type-button-2_UefFh__Main {
  letter-spacing: .15px;
  font-size: 12px;
  font-style: normal;
  font-weight: 580;
  line-height: 15px;
}

.Ui-button-module_btn_uGeHD__Main[disabled] {
  cursor: not-allowed;
}

.Ui-button-module_btn--full-width_k1YXj__Main {
  width: 100%;
}

.Ui-button-module_btn--color-inherited_Mgi7S__Main {
  background-color: inherit;
  color: currentColor;
}

.Ui-button-module_btn--size-large_63Q3p__Main {
  height: 50px;
  min-height: 50px;
  padding: 0 20px;
}

.Ui-button-module_btn--size-medium_xG5QX__Main {
  height: 40px;
  min-height: 40px;
  padding: 0 20px;
}

.Ui-button-module_btn--size-small_YfTNK__Main {
  height: 30px;
  min-height: 30px;
  padding: 0 20px;
}

.Ui-button-module_btn--contained_y75Bp__Main {
  background-color: var(--buttons-filled-bg);
  color: var(--buttons-filled-text);
  border: none;
}

.Ui-button-module_btn--contained_y75Bp__Main:hover, .Ui-button-module_btn--contained_y75Bp__Main:active {
  background-color: var(--buttons-hover);
}

.Ui-button-module_btn--contained_y75Bp__Main[disabled] {
  background-color: var(--disabled-bg);
  color: var(--disabled-text);
  cursor: not-allowed;
}

.Ui-button-module_btn--outlined_fhkfj__Main {
  color: var(--buttons-outlined-text);
  border: 1px solid var(--buttons-outlined-stroke);
  background-color: rgba(0, 0, 0, 0);
}

.Ui-button-module_btn--outlined_fhkfj__Main:hover, .Ui-button-module_btn--outlined_fhkfj__Main:active {
  border-color: var(--buttons-hover);
  color: var(--buttons-hover);
}

.Ui-button-module_btn--outlined_fhkfj__Main:hover svg, .Ui-button-module_btn--outlined_fhkfj__Main:active svg {
  fill: var(--icons-active);
}

.Ui-button-module_btn--outlined_fhkfj__Main[disabled] {
  color: var(--disabled-text);
  background-color: var(--disabled-bg);
  border-color: var(--disabled-stroke);
  cursor: not-allowed;
}

.Ui-button-module_btn--outlined_fhkfj__Main[disabled] svg {
  fill: var(--disabled-icons);
}

.Ui-button-module_btn--text_SwjQ7__Main {
  color: var(--buttons-text);
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.Ui-button-module_btn--text_SwjQ7__Main:hover, .Ui-button-module_btn--text_SwjQ7__Main:active {
  color: var(--buttons-hover);
}

.Ui-button-module_btn--text_SwjQ7__Main:hover svg, .Ui-button-module_btn--text_SwjQ7__Main:active svg {
  fill: var(--icons-active);
}

.Ui-button-module_btn--text_SwjQ7__Main[disabled] {
  color: var(--disabled-text);
  cursor: not-allowed;
}

.Ui-button-module_btn--text_SwjQ7__Main[disabled] svg {
  fill: var(--disabled-icons);
}

@media (max-width: 660px) {
  .Ui-button-module_btn--contained_y75Bp__Main:not([disabled]):hover {
    background-color: var(--buttons-filled-bg);
  }

  .Ui-button-module_btn--outlined_fhkfj__Main:not([disabled]):hover {
    border-color: var(--buttons-outlined-stroke);
    color: var(--buttons-outlined-text);
  }

  .Ui-button-module_btn--outlined_fhkfj__Main:not([disabled]):hover svg {
    fill: inherit;
  }

  .Ui-button-module_btn--text_SwjQ7__Main:not([disabled]):hover {
    color: var(--buttons-text);
  }

  .Ui-button-module_btn--text_SwjQ7__Main:not([disabled]):hover svg {
    fill: inherit;
  }
}

.Loader-module_loader_Ukoov__Main {
  justify-content: center;
  width: 100%;
  display: flex;
}

.Loader-module_loader__spinner_cp5N7__Main {
  width: var(--width);
  height: var(--height);
  border-radius: 50%;
  animation: 1s linear infinite Loader-module_rotate_p-j1x__Main;
  position: relative;
}

.Loader-module_loader__spinner_cp5N7__Main:before {
  content: "";
  box-sizing: border-box;
  border: 3px solid var(--buttons-switch-theme-dark-icon-bg);
  border-radius: 50%;
  animation: 1.5s linear infinite Loader-module_prixClipFix_yGEF1__Main;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

@keyframes Loader-module_rotate_p-j1x__Main {
  to {
    transform: rotate(360deg);
  }
}

@keyframes Loader-module_prixClipFix_yGEF1__Main {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  to {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.PostModerator-module_post-moderator_-OScw__Main {
  margin-left: 10px;
  position: relative;
}

.PostModerator-module_post-moderator--list_Gagd5__Main {
  margin-right: 10px;
}

.PostModerator-module_post-moderator__menu_dMwJm__Main {
  z-index: 2;
  border: 1px solid var(--line-line);
  background-color: var(--bg-block);
  cursor: pointer;
  border-radius: 10px;
  flex-direction: column;
  gap: 10px;
  width: 135px;
  margin-top: 10px;
  padding: 10px;
  display: flex;
  position: absolute;
  right: 0;
}

.PostModerator-module_post-moderator__menu--list_tdtp7__Main {
  left: 0;
}

.PostModerator-module_post-moderator__menu--item_S-R-8__Main {
  height: 40px;
  color: var(--list-text);
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.PostModerator-module_post-moderator__menu--item_S-R-8__Main:hover {
  background-color: var(--bg-component);
}

.PostModerator-module_post-moderator__button_YyDli__Main {
  background-color: var(--bg-component);
  cursor: pointer;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.PostModerator-module_post-moderator__button_YyDli__Main svg {
  fill: var(--icons-primary);
}

.PostModerator-module_post-moderator__button--active_xTrdP__Main {
  background-color: var(--bg-prime);
}

.PostModerator-module_post-moderator__button--active_xTrdP__Main svg {
  fill: var(--icons-white);
}

.StructuredBody-module_structured-body_kJxXZ__Main {
  color: var(--text-primary);
  overflow-wrap: break-word;
  flex-direction: column;
  gap: 20px;
  display: flex;
}

.StructuredBody-module_structured-body_kJxXZ__Main li {
  list-style: outside;
}

.StructuredBody-module_structured-body_kJxXZ__Main ul {
  padding-left: 40px;
}

.StructuredBody-module_structured-body__wrapper_qe2Y2__Main {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 10px;
  display: flex;
}

.StructuredBody-module_structured-body__text_H-U5j__Main {
  word-break: break-word;
  color: var(--text-primary);
}

.StructuredBody-module_structured-body__text_H-U5j__Main a {
  color: var(--text-link-active);
}

.StructuredBody-module_structured-body__meta-embeds_9qvhj__Main, .StructuredBody-module_structured-body__meta-embeds_9qvhj__Main > div {
  width: 100%;
}

@media (max-width: 660px) {
  .StructuredBody-module_structured-body__meta-embeds_9qvhj__Main {
    justify-content: center;
  }

  .StructuredBody-module_structured-body__meta-embeds_9qvhj__Main > iframe {
    max-height: 620px;
  }
}

